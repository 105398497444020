import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AdvisorDto, UserStatusEnum } from 'src/app/dtos/advisor.dto';
import { ExportData } from 'src/app/dtos/export-data.dto';
import { SpecialistToVerifyDto } from 'src/app/dtos/specialist-to-verify.dto';
import { AdvisorWithClientInfo } from 'src/app/interfaces/advisor-with-client-info.interface';
import { AdvisorService } from 'src/app/services/advisor.service';
import { convertToMoney } from 'src/app/utils/convertToMoney';
import { FormatDate, FormatDateAdvisor } from 'src/app/utils/formatDate.util';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
@Component({
  selector: 'advisor-resume',
  templateUrl: './advisor-resume.component.html',
  styleUrls: ['./advisor-resume.component.scss']
})
export class AdvisorResumeComponent implements OnInit {

  users: AdvisorWithClientInfo[] = [];
  userIds = [];
  countPremiumClients: number[] = [];
  emails: string[] = [];
  arrayData: ExportData[] = [];
  specialistToVerify: SpecialistToVerifyDto = {
    id: "",
    email: "",
    status: "",
    canBuyPremium: false,
    imageData: "https://advisor-walkr-bucket.s3.amazonaws.com/profile-icon-new.png",
    personalInfo: {}
  };

  formatDate = FormatDate;
  formatDateAdvisor = FormatDateAdvisor;
  convertToMoney = convertToMoney;
  jsonStringfy = JSON.stringify;

  plannerToRemove = {
    email: null,
    plannerId: null
  }
  closeResult: string;
  resultList: any
  messageRefused: string = "";
  approved: boolean;
  modalRefSpecialistToVerify: NgbModalRef;
  requestNewPersonalData: boolean = true;
  disableAccess: boolean = false;
  requestOption: string = 'requestNewPersonalData';
  specialistID: string = '';

  constructor(
    private readonly ngxSpinnerService: NgxSpinnerService,
    private readonly advisorService: AdvisorService,
    private readonly toastrService: ToastrService,
    private readonly modalService: NgbModal
  ) { }

  async ngOnInit() {
    this.ngxSpinnerService.show();
    await this.getData()
    await this.getUsersQuantity()
    this.ngxSpinnerService.hide();
  }

  async getData() {
    try {
      const result = await this.advisorService.list().toPromise();
      this.users = result.data;
      console.log(result.data)
      this.emails = result.data.map(email => email.email)
      await this.getPremiumClients();

      await this.getUsersQuantity();
    } catch (error) {
      console.error("Erro ao obter os dados da API:", error);
    }
  }

  async getUsersQuantity() {
    try {
      const response = await this.advisorService.getExportData().toPromise();

      // Inicializar totalClients e goldClients como indefinido
      this.users.forEach(user => {
        user.totalClients = 0;
        user.goldClients = 0;
      });

      const advisorData = response.data;

      // Agrupar os dados dos usuários por advisorID
      const groupedData = advisorData.reduce((acc, curr) => {
        if (!acc[curr.advisorID]) {
          acc[curr.advisorID] = { totalClients: 0, goldClients: 0 };
        }
        acc[curr.advisorID].totalClients += 1;
        if (curr.userIsGold === 'Sim') {
          acc[curr.advisorID].goldClients += 1;
        }
        return acc;
      }, {});

      // Atualizar os valores nos usuários
      this.users.forEach(user => {
        if (groupedData[user.advisorID]) {
          user.totalClients = groupedData[user.advisorID].totalClients;
          user.goldClients = groupedData[user.advisorID].goldClients;
        } else {
          user.totalClients = 0;
          user.goldClients = 0;
        }
      });

      this.ngxSpinnerService.hide();
    } catch (error) {
      console.error("Erro ao obter os dados da API:", error);
    }
  }

  arrayToCommaSeparatedString(array: string[]): string {
    if (!array) return ""
    return array.map(word => word.split(' ').map(subWord => subWord.charAt(0).toUpperCase() + subWord.slice(1).toLowerCase()).join(' ')).join(', ');
  }

  capitalizeWords(str: string): string {
    if (!str) return ""
    return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
  }

  async changeCommunity(event, id: string) {
    this.ngxSpinnerService.show();
    await this.advisorService.updateCommunity({ id, community: event.target.checked }).toPromise();
    await this.getData();
    this.ngxSpinnerService.hide();
  }

  async getPremiumClients() {
    try {
      const response = await this.advisorService.getCountPremiumClients().toPromise();
      this.countPremiumClients = response.data;
      this.ngxSpinnerService.hide();
    } catch (error) {
      console.error("Erro ao processar os clientes premium:", error);
    }
  }

  async exportData() {

    this.ngxSpinnerService.show();

    await this.advisorService.getExportData().subscribe(
      success => {
        this.arrayData = success.data;

        const workbook = XLSX.utils.book_new();

        workbook.Props = {};
        workbook.Props.Title = 'Resumo - Walkr Advisor';

        workbook.SheetNames.push('Página 1');

        let wscols = [
          { width: 30 }, //col 1
          { width: 30 }, //col 2
          { width: 30 }, //col 3
          { width: 30 }, //col 4
          { width: 30 }, //col 5
          { width: 30 }, //col 6
          { width: 30 }, //col 7
        ];

        const advisorCounts = {};
        this.arrayData.forEach(item => {
          const { advisorID } = item;
          advisorCounts[advisorID] = (advisorCounts[advisorID] || 0) + 1;
        });

        this.arrayData.sort((a, b) => {
          const countA = advisorCounts[a.advisorID];
          const countB = advisorCounts[b.advisorID];

          if (countA === countB) {
            if (a.userName !== '' && b.userName === '') return -1;
            if (a.userName === '' && b.userName !== '') return 1;
          }

          return countB - countA;
        });

        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.arrayData);

        worksheet['!cols'] = wscols;

        workbook.Sheets['Página 1'] = worksheet;

        workbook.Sheets['Página 1']['A1'].v = 'Email';
        workbook.Sheets['Página 1']['B1'].v = 'Advisor ID';
        workbook.Sheets['Página 1']['C1'].v = 'Nome do cliente';
        workbook.Sheets['Página 1']['D1'].v = 'Email do cliente';
        workbook.Sheets['Página 1']['E1'].v = 'Celular no cliente';
        workbook.Sheets['Página 1']['F1'].v = 'Cliente Gold?';
        workbook.Sheets['Página 1']['G1'].v = 'Data da compra';

        XLSX.writeFile(workbook, 'resumo-especialistas-walkr-advisor.xlsx');

        this.ngxSpinnerService.hide();

      },
      error => console.log(error)
    );

  }

  async deletePlanner(email: string) {
    this.ngxSpinnerService.show();
    await this.advisorService.deleteAllOfUser(email).subscribe(
      () => {
        this.ngxSpinnerService.hide();
      },
      error => console.log(error)
    );
  }


  async showModalRemovePlanner(content, email, index) {
    const emailUser = this.emails[index];
    this.plannerToRemove = {
      email: email,
      plannerId: emailUser,
    };

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.removePlanner(emailUser);
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  async showModalApproveCommunity(content, index) {

    this.ngxSpinnerService.show();

    const email = this.users[index].email
    const canBuyPremium = this.users[index].canBuyPremium
    this.specialistToVerify.id = this.users[index]._id
    this.specialistToVerify.status = this.users[index].status
    this.specialistToVerify.email = email
    this.specialistToVerify.canBuyPremium = canBuyPremium

    const response = await this.advisorService.getPersonalInfoByEmail(email).toPromise();
    this.specialistToVerify.personalInfo = response.data

    const responsePhotoProfile = await this.advisorService.getImageDataProfile(email).toPromise();
    if (responsePhotoProfile.data) this.specialistToVerify.imageData = responsePhotoProfile.data.data

    this.ngxSpinnerService.hide();

    this.modalRefSpecialistToVerify = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });

    this.modalRefSpecialistToVerify.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.approved = true;
      this.canBuyPremium(this.specialistToVerify.id, true);
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  async showModalMessageRefused(content, email, disable) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.approved = false;
      console.log(this.specialistToVerify.personalInfo)
      if (!('job' in this.specialistToVerify.personalInfo)) {
        this.messageRefused = "";
        this.requestOption = 'requestNewPersonalData';
        this.requestNewPersonalData = true;
        this.disableAccess = false;
        return Swal.fire("O usuário precisa ter todos os dados cadastrais preenchidos!");
      }
      this.advisorService.sendRefusedEmail({ email, message: this.messageRefused, disapproved: disable }).subscribe(
        () => {
          this.toastrService.success("Mensagem enviada com sucesso!", 'Sucesso', {
            progressBar: true
          });
          this.messageRefused = "";
          this.canBuyPremium(this.specialistToVerify.id, disable);
        },
        error => console.log(error)
      );
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  addLineBreak(event: any) {
    if (event.keyCode === 13) {
      this.messageRefused += '\n';
      event.preventDefault();
    }
  }

  removePlanner(email: string) {
    this.advisorService.deleteAllOfUser(email).subscribe(
      success => {
        this.ngxSpinnerService.show();
        this.getData();
      },
      error => console.log(error)
    );
  }

  canBuyPremium(id: string, disable: boolean) {
    if (!('job' in this.specialistToVerify.personalInfo)) return Swal.fire("O usuário precisa ter todos os dados cadastrais preenchidos!");
    this.ngxSpinnerService.show();
    this.advisorService.updateCanBuyPremium({ id, canBuyPremium: this.approved }).subscribe(
      () => {
        this.modalRefSpecialistToVerify.dismiss('Cancel click');
        this.modalRefSpecialistToVerify = null;
        if (this.approved) {
          this.getData();
          this.ngxSpinnerService.hide();
        } else {
          this.advisorService.changeStatus({ email: this.specialistToVerify.email, status: this.requestNewPersonalData && !disable ? UserStatusEnum.requestNewPersonalData : UserStatusEnum.disabled }).subscribe(
            () => {
              this.requestOption = 'requestNewPersonalData';
              this.requestNewPersonalData = true;
              this.disableAccess = false;
              this.getData();
              this.ngxSpinnerService.hide();
            },
            error => console.log(error)
          );
        }
      },
      error => console.log(error)
    );
  }

  updateRequestNewPersonalData() {
    if (this.requestOption === 'disableAccess') {
      this.disableAccess = true;
      this.requestNewPersonalData = false;
    } else {
      this.disableAccess = false;
      this.requestNewPersonalData = true;
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }

  }

}
