import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CombinedDataPurchasesInterface } from 'src/app/dtos/combined-data-purchases.dto';
import { AdvisorService } from 'src/app/services/advisor.service';
import { convertToMoney } from 'src/app/utils/convertToMoney';
import { FormatDate } from 'src/app/utils/formatDate.util';

@Component({
  selector: 'buys-gold-resume',
  templateUrl: './buys-gold-resume.component.html',
  styleUrls: ['./buys-gold-resume.component.scss']
})
export class BuysGoldResumeComponent implements OnInit {

  //users: CombinedDataPurchasesInterface[] = [];
  users: any[];
  arrayData: any[];
  specialistID: string = '';
  showPaymentColumn = false;

  formatDate = FormatDate;
  convertToMoney = convertToMoney;

  constructor(
    private readonly ngxSpinnerService: NgxSpinnerService,
    private readonly advisorService: AdvisorService,
    private route: ActivatedRoute
  ) { }

  async ngOnInit() {
    this.ngxSpinnerService.show();
    this.route.queryParams.subscribe(params => {
      this.specialistID = params['specialistID'];
    });
    await this.getData();
  }

  async getData() {
    try {
      const result = await this.advisorService.getExportData().toPromise();
      this.arrayData = result.data;
      console.log("this.arrayData:", this.arrayData);

      // Filtrar os dados com base no specialistID
      this.users = this.arrayData.filter(user => user.advisorID === this.specialistID);
      console.log("this.users:", this.users);

      this.ngxSpinnerService.hide();
    } catch (error) {
      console.error("Erro ao obter os dados da API:", error);
      this.ngxSpinnerService.hide();
    }
  }

  async changeCheckCommissionPayment(event, id: string) {
    this.ngxSpinnerService.show();
    await this.advisorService.updateCheckCommissionPayment({ id, checkCommissionPayment: event.target.checked }).toPromise();
    await this.getData();
    this.ngxSpinnerService.hide();
  }

  updateShowPaymentColumn() {
    this.showPaymentColumn = this.users.some(user => user.approvedDate !== undefined && user.approvedDate !== '');
  }

}
