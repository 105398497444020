<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page">
            <a [routerLink]="['/pages/dashboard']">Dashboard</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Clientes remarketing</li>
    </ol>
</nav>

<div class="card card-radius mb-3">
    <div class="card-body">
        <div class="form-group">
            <label for="term">Buscar</label>
            <div class="input-group">
                <input (input)="filter = $event.target.value" type="text" class="form-control mr-4"
                    placeholder="Digite o texto para buscar">
                <button (click)="list()" class="btn btn-primary">Buscar</button>
            </div>
        </div>
    </div>
</div>

<div class="card card-radius mt-3">
    <div class="card-body">
        <div class="buttons-export d-flex">
            <div class="w-50 d-flex justify-content-start">
                <a (click)="exportData()" class="btn btn-outline-secondary">Exportar dados</a>
            </div>
            <div class="w-50 d-flex justify-content-end">
                <a (click)="selectAll()" class="btn btn-outline-secondary">Selecionar todos</a>
            </div>
        </div>
        <div class="table-responsive card-radius">
            <table class="table table-sm table-hover table-striped">
                <thead class="button-color">
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">Nome</th>
                        <th scope="col">Profissão</th>
                        <th scope="col">Data de nascimento</th>
                        <th scope="col">E-mail</th>
                        <th scope="col">Telefone</th>
                        <th scope="col">ID</th>
                        <th scope="col">Data de criação</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let item of resultList |  paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }; let i= index">
                        <td><input [id]="'Checkbox' + i"
                                (change)="selectedClient($event, item.email, item.occupation, item.birthDate ? FormatDate(item.birthDate): '', item.name, item.phone, item.createdAt ? FormatDate(item.createdAt): '')"
                                [checked]="isChecked" type="checkbox">
                        </td>
                        <td>{{item.name}}</td>
                        <td>{{item.occupation}}</td>
                        <td>{{item.birthDate ? FormatDate(item.birthDate): ''}}</td>
                        <td>{{item.email}}</td>
                        <td>{{item.phone | phone}}</td>
                        <td>{{item.advisorID}}</td>
                        <td>{{FormatDate(item.createdAt)}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="d-flex">
            <app-table-size-selector (tableSizeChange)="onTableSizeChange($event)"></app-table-size-selector>

            <pagination-controls (pageChange)="currentPage = $event; list()" previousLabel="Anterior" nextLabel="Próximo">
            </pagination-controls>
        </div>
    </div>
</div>