export const environment = {
  production: false,
 Apis: {
    accessControl: 'https://superadmin.walkr.app',
    // accessControl: 'http://44.202.201.247:3001',
    // accessControl: 'http://localhost:3001',
    // accessControl: 'https://superadmin-dev-api.walkr.app',
    // accessControl: 'http://3.208.237.240:3001',
    logs: 'https://logs.walkr.app',
    // logs: 'http://ec2-3-208-237-240.compute-1.amazonaws.com:3000',
    // logs: 'http://localhost:3000',
//     advisor: 'https://walkr-advisor-dev.walkr.app',
    advisor: 'https://walkr-advisor-prod.walkr.app'
    // advisor: 'http://127.0.0.1:7000'
    // advisor: 'http://44.204.34.47:7000/docs'
  },

  providerOptions: {
    useCustomRpc: false,
    // customRpcURL: 'http://ec2-3-208-237-240.compute-1.amazonaws.com',
    customRpcURL: 'http://ec2-3-229-130-214.compute-1.amazonaws.com',
    network: 'ropsten',
    alchemy: 'Arw_i_nB56rZzUfSpOmfu61Ka9z2mV-l',
    etherscan: 'CHGJCC3EVXQIHKMN6EYHY1AJZ719R82AJT',
    infuraId: '8cbfdf95c438434b8a33121dca8e75f0',
    infuraSecret: '',
  },
};
