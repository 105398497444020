import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ClientGetResponseDto } from '../../dtos/client-get-response.dto';
import { ClientService } from '../../services/client.service';
import moment from 'moment';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormatDate } from "src/app/utils/formatDate.util";

@Component({
  selector: 'app-remove-customers',
  templateUrl: './remove-customers.component.html',
  styleUrls: ['./remove-customers.component.scss']
})
export class RemoveCustomersComponent implements OnInit {

  resultList: ClientGetResponseDto[];

  filter: string = ""

  currentPage: number = 1;
  itemsPerPage: number = 20;

  closeResult: string;

  clientToRemove = {
    name: null,
    email: null,
    phone: null
  };

  todayDate: moment.Moment = moment(new Date());

  formatDate = FormatDate

  constructor(
    private ngxSpinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private clientService: ClientService,
    private modalService: NgbModal
  ) { }


  // função iniciada ao carregar página
  ngOnInit(): void {
    this.ngxSpinnerService.show();
    this.list();
  }

  list() {
    if (!this.filter) {
      this.clientService.listUsersByPagination(this.itemsPerPage, this.currentPage).subscribe(
        success => {
          this.resultList = success
          this.ngxSpinnerService.hide();
        },
        error => console.error(error)
      )
    } else {
      this.ngxSpinnerService.show();
      this.clientService.listUserByFilter({
        text: this.filter,
        dateRange: {
          initialDate: null,
          finalDate: null
        },
      }).subscribe(
        success => {
          this.resultList = success
          this.ngxSpinnerService.hide();
        },
        error => console.error(error)
      )
    }

  }


  showModalRemoveClient(content, userName, userEmail, userPhone) {
    this.clientToRemove = {
      name: userName,
      email: userEmail,
      phone: userPhone
    };

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`
      this.removeClient(userEmail);
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  removeClient(userEmail: string) {
    this.clientService.deleteClient(userEmail).subscribe(
      success => {
        this.ngxSpinnerService.show();
        this.list();
      },
      error => console.log(error)
    )
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  runError(exception: any) {
    const toastr = this.toastrService.error(exception.error.erros[0], 'Error', {
      progressBar: true
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.ngxSpinnerService.hide();
      });
  }

}