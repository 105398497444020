<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="pa    ge">
            <a [routerLink]="['/pages/dashboard']">Dashboard</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Resumo Walkr Advisor</li>
    </ol>
</nav>


<div class="card card-radius mt-3">
    <div class="card-body">
        <div class="buttons-export d-flex">
            <div class="w-50 d-flex justify-content-start">
                <a (click)="exportData()" class="btn btn-outline-secondary">Exportar dados</a>
            </div>
        </div>
        <div class="table-responsive card-radius">
            <table class="table table-sm table-hover table-striped">
                <thead class="button-color">
                    <tr>
                        <th scope="col">E-mail</th>
                        <th scope="col">ID</th>
                        <th scope="col">Total de clientes</th>
                        <th scope="col">Clientes GOLD</th>
                        <th scope="col">Data de criação</th>
                        <th scope="col">Aprovado</th>
                        <th scope="col">Cadastro</th>
                        <th scope="col">Premium</th>
                        <th scope="col">Excluir</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let user of users; let i = index">
                        <td class="text-black">{{ user.email }}</td>
                        <td class="text-black">{{ user.advisorID }}</td>
                        <td class="text-black"
                            [routerLink]="['/pages/buys-gold-resume']"
                            [queryParams]="{ specialistID: user.advisorID }">
                            {{ user.totalClients !== undefined ? user.totalClients : 'Carregando...' }}
                        </td>
                        <td class="text-black"
                            [routerLink]="['/pages/buys-gold-resume']"
                            [queryParams]="{ specialistID: user.advisorID }">
                            {{ user.goldClients !== undefined ? user.goldClients : 'Carregando...' }}
                        </td>
                        <td class="text-black">{{ formatDate(user.createdAt) }}</td>
                        <td>
                            <button (click)="showModalApproveCommunity(modalCanBuyPremium, i)"
                                [disabled]="user.status === 'disabled'"
                                [ngClass]="{ 'text-link': user.status !== 'disabled' }" class="border-0 bg-transparent"
                                style="font-size:14px;">
                                {{ user.status === 'disabled' ? "Desabilitado" : "Ver mais" }}
                            </button>

                            <ng-container *ngIf="user.canBuyPremium">
                                <i class="fas fa-check text-success"></i>
                            </ng-container>
                            <ng-container *ngIf="user.status !== 'disabled' && !user.canBuyPremium">
                                <i class="fas fa-times text-danger"></i>
                            </ng-container>
                        </td>
                        <td class="text-black">{{ (user.birthdate).length > 0 ? "Completo" : "Básico" }}</td>
                        <td class="text-black">
                            <ng-container *ngIf="user.premium">
                                <i class="fas fa-check text-success"></i>
                            </ng-container>
                            <ng-container *ngIf="!user.premium">
                                <i class="fas fa-times text-danger"></i>
                            </ng-container>
                        </td>
                        <td>
                            <button (click)="showModalRemovePlanner(myModal, user.email, i)"
                                class="border-0 bg-transparent">
                                <em class="fas fa-trash"></em>
                            </button>
                        </td>
                    </tr>
            </table>
        </div>
    </div>
</div>

<ng-template #myModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Você tem certeza que deseja excluir {{ plannerToRemove.email }}?
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <h5>Dados do Assessor</h5>
        <ul>
            <li>Email: {{plannerToRemove.email}}</li>
        </ul>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" aria-label="Cancel" (click)="modal.dismiss('Cancel click')">
            <span aria-hidden="true">Cancelar</span>
        </button>
        <button type="button" class="btn btn-outline-danger" (click)="modal.close('Save click')">Excluir</button>
    </div>
</ng-template>

<ng-template #modalCanBuyPremium let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            {{ specialistToVerify.canBuyPremium ? "Desabilitar " : "Aprovar " }} {{ specialistToVerify.personalInfo.name
            }}?</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <h5>Dados do Assessor</h5>
        <div class="d-flex justify-content-center mt-3 mb-3">
            <img [src]="specialistToVerify.imageData" width="150" class="rounded-circle" />
        </div>
        <ul>
            <li>Nome: {{ specialistToVerify.personalInfo.name }}</li>
            <li>CPF: {{ specialistToVerify.personalInfo.cpf }}</li>
            <li>Email: {{ specialistToVerify.email }}</li>
            <li>Celular: {{ specialistToVerify.personalInfo.phone }}</li>
            <li>Data de nascimento: {{ formatDateAdvisor(specialistToVerify.personalInfo.birthdate) }}</li>
            <li>Profissão: {{ capitalizeWords(specialistToVerify.personalInfo.job) }}</li>
            <li>Empresa: {{ specialistToVerify.personalInfo.company }}</li>
            <li>Instituição vinculada: {{ specialistToVerify.personalInfo.linkedFinancialInstitution }}</li>
            <li>Cidade: {{ specialistToVerify.personalInfo.city }}</li>
            <li>Tempo de experiência: {{ specialistToVerify.personalInfo.experienceTime }} anos</li>
            <ng-container *ngIf="specialistToVerify.personalInfo.remoteService">
                <li>Atende remoto: Sim</li>
            </ng-container>
            <ng-container *ngIf="!specialistToVerify.personalInfo.remoteService">
                <li>Atende remoto: Não</li>
            </ng-container>
            <li>Perfil de cliente: {{ arrayToCommaSeparatedString(specialistToVerify.personalInfo.clientProfile) }}</li>
            <li>Competências: {{ arrayToCommaSeparatedString(specialistToVerify.personalInfo.mainSkills) }}</li>
            <li>Certificações: {{ arrayToCommaSeparatedString(specialistToVerify.personalInfo.financialCertifications)
                }}</li>
            <li>Data de criação: {{ formatDate(specialistToVerify.personalInfo.createdAt) }}</li>
        </ul>
    </div>
    <div class="modal-footer">
        <button *ngIf="specialistToVerify.canBuyPremium" type="button" class="btn btn-outline-danger"
            (click)="showModalMessageRefused(modalMessageRefused, specialistToVerify.email, true)">Desabilitar
            acesso</button>
        <button *ngIf="!specialistToVerify.canBuyPremium" type="button" class="btn btn-outline-danger"
            (click)="showModalMessageRefused(modalMessageRefused, specialistToVerify.email, false)">Recusar</button>
        <button [disabled]="specialistToVerify.canBuyPremium" type="button" class="btn btn-outline-success"
            (click)="modal.close('Save click')">Aprovar</button>
    </div>
</ng-template>

<ng-template #modalMessageRefused let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Explique porque {{ specialistToVerify.personalInfo.name }} terá
            sua adesão recusada ou desabilitada:
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div *ngIf="!specialistToVerify.canBuyPremium" class="form-check mx-auto pt-3">
        <input class="form-check-input" type="radio" name="requestOption" id="requestNewPersonalData"
            [(ngModel)]="requestOption" (change)="updateRequestNewPersonalData()" value="requestNewPersonalData">
        <label class="form-check-label" for="requestNewPersonalData">Solicitar revisão de dados cadastrais</label>
    </div>
    <div *ngIf="!specialistToVerify.canBuyPremium" class="form-check mx-auto pt-1">
        <input class="form-check-input" type="radio" name="requestOption" id="disableAccess" [(ngModel)]="requestOption"
            (change)="updateRequestNewPersonalData()" value="disableAccess">
        <label class="form-check-label" for="disableAccess">Desabilitar acesso</label>
    </div>
    <div class="modal-body">
        <textarea class="form-control" rows="8" [(ngModel)]="messageRefused" (keydown.enter)="addLineBreak($event)"
            maxlength="158"></textarea>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" aria-label="Cancel"
            (click)="modal.dismiss('Cancel click')">
            <span aria-hidden="true">Cancelar</span>
        </button>
        <button type="button"
            [disabled]="!messageRefused || (requestNewPersonalData && specialistToVerify.status === 'request-new-personal-data')"
            class="btn btn-outline-success" (click)="modal.close('Save click')">Enviar</button>
    </div>
</ng-template>
