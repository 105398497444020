<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="pa    ge">
            <a [routerLink]="['/pages/dashboard']">Dashboard</a>
        </li>
        <li class="breadcrumb-item" aria-current="pa    ge">
            <a [routerLink]="['/pages/advisor-resume']">Resumo Walkr Advisor</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Clientes</li>
    </ol>
</nav>


<div class="card card-radius mt-3">
    <div class="card-body">
        <div class="buttons-export d-flex">
            <div class="table-responsive card-radius">
                <table class="table table-sm table-hover table-striped">
                    <thead class="button-color">
                        <tr>
                            <th scope="col">Nome</th>
                            <th scope="col">E-mail</th>
                            <th scope="col">Celular</th>
                            <th scope="col">GOLD</th>
                            <th scope="col">Data da compra</th>
                            <!-- <th scope="col" *ngIf="showPaymentColumn">Pagamento</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let user of users; let i = index">
                            <td class="text-black">{{ user.userName }}</td>
                            <td class="text-black">{{ user.userEmail }}</td>
                            <td class="text-black">{{ user.userPhone }}</td>
                            <td class="text-black">
                              <ng-container *ngIf="user.userIsGold == 'Não'">
                                <i class="fas fa-times text-danger"></i>
                              </ng-container>
                              <ng-container *ngIf="user.userIsGold == 'Sim'"><i class="fas fa-check text-success"></i>
                              </ng-container>
                            </td>
                            <td class="text-black">{{ user.goldDate }}</td>
                            <!-- <td class="text-black" *ngIf="user.approvedDate">
                                <ng-container *ngIf="user.checkCommissionPayment; else checkboxBlock">
                                    PAGO
                                </ng-container>
                                <ng-template #checkboxBlock>
                                    <input (change)="changeCheckCommissionPayment($event, user.userID)"
                                        [checked]="user.checkCommissionPayment" type="checkbox">
                                </ng-template>
                            </td> -->
                        </tr>
                </table>
            </div>
        </div>
    </div>
</div>
