import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { ClientGetResponseDto } from "../dtos/client-get-response.dto";
import { BaseService } from "./base.service";
import { ClientUserResponseDto } from "../client-user.dto";
import { ProgressDto } from "../dtos/progress.dto";
import { PdfTableScreenDto } from "../dtos/pdf-table-screen.dto";
import { DateRange } from "@angular/material/datepicker";
import { IFilter } from "../interfaces/filter.interface";

@Injectable()
export class ClientService extends BaseService {

    private url: string = environment.Apis.accessControl;

    constructor(
        private httpClient: HttpClient,
    ) {
        super();
    }

    countAll(): Observable<number> {
        return this.httpClient
            .get(`${this.url}/client/count/all`, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    countActive(): Observable<number> {
        return this.httpClient
            .get(`${this.url}/client/count/active`, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    countInactive(): Observable<number> {
        return this.httpClient
            .get(`${this.url}/client/count/inactive`, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    countWaitActivation(): Observable<number> {
        return this.httpClient
            .get(`${this.url}/client/count/wait-activation`, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getUserById(id: string): Observable<ClientUserResponseDto> {
        return this.httpClient
            .get(`${this.url}/client/user/${id}`, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    listAll(): Observable<ClientGetResponseDto[]> {
        return this.httpClient
            .get(`${this.url}/client/listAll`, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    listUsersByAdvisorID(advisorID: string): Observable<ClientGetResponseDto[]> {
        return this.httpClient
            .get(`${this.url}/client/listUsersByAdvisorID/${advisorID}`, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    listUsersByPagination(rows: number, page: number): Observable<ClientGetResponseDto[]> {
        return this.httpClient
            .post(`${this.url}/client/list-by-pagination`, { rows, page }, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    listUserByFilter(filter: IFilter): Observable<ClientGetResponseDto[]> {
        return this.httpClient
            .post(`${this.url}/client/list-filtered`,
                filter
                , this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    listUserRemarketingByFilter(text: string): Observable<ClientGetResponseDto[]> {
        return this.httpClient
            .get(`${this.url}/client/list-filtered-remarketing/${text}`, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    listActive(): Observable<ClientGetResponseDto[]> {
        return this.httpClient
            .get(`${this.url}/client/active`, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    listInactive(): Observable<ClientGetResponseDto[]> {
        return this.httpClient
            .get(`${this.url}/client/inactive`, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    listRemarketing(): Observable<ClientGetResponseDto[]> {
        return this.httpClient
            .get(`${this.url}/client/user-remarketing`, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    listRemarketingByPagination(rows: number, page: number): Observable<ClientGetResponseDto[]> {
        return this.httpClient
            .post(`${this.url}/client/user-remarketing-pagination`, { rows, page }, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    listWaitActivation(): Observable<ClientGetResponseDto[]> {
        return this.httpClient
            .get(`${this.url}/client/wait-activation`, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    deleteClient(email: string): Observable<ClientGetResponseDto[]> {
        return this.httpClient
            .get(`${this.url}/client/delete/${email}`, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    clientProgress(email: string): Observable<ProgressDto> {
        return this.httpClient
            .get(`${this.url}/client/progress/${email}`, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    listClientsPdf(): Observable<any> {
        return this.httpClient
            .get(`${this.url}/client/pdf`, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    pdfTableScreen(email: string): Observable<PdfTableScreenDto> {
        return this.httpClient
            .get(`${this.url}/client/pdf/table/${email}`, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    exportTable(email: string): Observable<any> {
        return this.httpClient
            .get(`${this.url}/client/table/export/${email}`, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getAnalyticsSOReport(startDate: Date, finalDate: Date): Observable<any> {
        return this.httpClient
            .post(`${this.url}/client/analytics/so-report`, { startDate, finalDate }, this.authorizedHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }
}
