import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ClientGetResponseDto } from '../../dtos/client-get-response.dto';
import { ClientService } from '../../services/client.service';
import * as XLSX from 'xlsx';
import { FormatDate } from "src/app/utils/formatDate.util";

@Component({
  selector: 'app-check-rebuy',
  templateUrl: './check-rebuy.component.html',
  styleUrls: ['./check-rebuy.component.scss']
})
export class CheckRebuyComponent implements OnInit {

  resultList: ClientGetResponseDto[];

  term: string;

  currentPage: number = 1;
  itemsPerPage: number = 20;

  FormatDate = FormatDate

  arrayData = [];
  isChecked: boolean;

  filter: string = ""

  constructor(
    private ngxSpinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private clientService: ClientService,
  ) { }

  ngOnInit(): void {
    this.ngxSpinnerService.show();

    this.list();
  }

  list() {
    if (!this.filter) {
      this.clientService.listRemarketingByPagination(this.itemsPerPage, this.currentPage).subscribe(
        success => this.runListSuccess(success),
        error => this.runError(error)
      );
    } else {
      this.ngxSpinnerService.show();
      this.clientService.listUserRemarketingByFilter(this.filter).subscribe(
        success => this.runListSuccess(success),
        error => this.runError(error)
      );
    }
  }

  runListSuccess(success: ClientGetResponseDto[]): void {
    this.resultList = success
    this.ngxSpinnerService.hide();
  }

  runError(exception: any) {
    const toastr = this.toastrService.error(exception.error.erros[0], 'Error', {
      progressBar: true
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.ngxSpinnerService.hide();
      });
  }

  exportData() {
    const workbook = XLSX.utils.book_new();

    workbook.Props = {};
    workbook.Props.Title = 'Dados dos clientes ativos';

    workbook.SheetNames.push('Página 1');

    let wscols = [
      { width: 30 }, //col 1
      { width: 30 }, //col 2
      { width: 30 }, //col 3
      { width: 30 }, //col 4
      { width: 30 }, //col 5
      { width: 30 } //col 6
    ];

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.arrayData);

    worksheet['!cols'] = wscols;

    workbook.Sheets['Página 1'] = worksheet;

    workbook.Sheets['Página 1']['A1'].v = 'Nome';
    workbook.Sheets['Página 1']['B1'].v = 'Profissão';
    workbook.Sheets['Página 1']['C1'].v = 'Data de Nascimento';
    workbook.Sheets['Página 1']['D1'].v = 'Telefone';
    workbook.Sheets['Página 1']['E1'].v = 'Email';
    workbook.Sheets['Página 1']['F1'].v = 'Data de Criação';

    XLSX.writeFile(workbook, 'dados-remarketing.xlsx');
    this.arrayData = []
    const length = Number(this.itemsPerPage);
    new Array(length).fill(0).forEach((_, index) => {
      const checkboxElement: HTMLInputElement = document.getElementById(`Checkbox${index}`) as HTMLInputElement;
      if (checkboxElement.checked) checkboxElement.checked = !checkboxElement.checked
    })
  }

  selectAll() {
    this.isChecked = true;
    this.resultList.forEach(user => {
      this.arrayData.push({ name: user.name, occupation: user.occupation, birthDate: user.birthDate ? FormatDate(user.birthDate) : "", phone: user.phone, email: user.email, createdAt: user.createdAt ? FormatDate(new Date(user.createdAt)) : "" });
    })
    console.log(this.arrayData)
  }

  selectedClient(event, userEmail: string, userOccupation: string, userBirthDate: string, userName: string, userPhone: string, userCreatedAt: string) {
    if (event.target.checked == true) {
      this.arrayData.push({ name: userName, occupation: userOccupation, userBirthDate: userBirthDate, phone: userPhone, email: userEmail, createdAt: userCreatedAt });
    } else {
      if (this.arrayData.length != 0) {
        let indexName = this.arrayData.indexOf({ name: userName, occupation: userOccupation, userBirthDate: userBirthDate, phone: userPhone, email: userEmail, createdAt: userCreatedAt });
        this.arrayData.splice(indexName, 1);
      }
    }
  }

  onTableSizeChange(size: number) {
    this.itemsPerPage = size;
    this.list()
  }
}