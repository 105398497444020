export class AdvisorDto {
    _id: string;
    email: string;
    type: TypeUserEnum;
    password: string;
    status: UserStatusEnum;
    advisorID: string;
    clientsInfo: ClientsInfo[];
    createdAt: Date;
    vinculationDate?: string;
    premium?: boolean;
    stripeSessionID?: string;
    stripeCustomerID?: string;
    community?: boolean;
    canBuyPremium?: boolean;
    birthdate?: string;
}

enum TypeUserEnum {
    admin = 'admin',
    advisor = 'advisor'
}

export enum UserStatusEnum {
    firstPassword = 'first-password',
    active = 'active',
    resetNeeded = 'reset-needed',
    disabled = 'disabled',
    requestNewPersonalData = 'request-new-personal-data'
}

interface ClientsInfo {
    readonly userId: string;
    readonly contacted: boolean;
}
